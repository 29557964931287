import Avatar from 'react-avatar';
import { useUser } from "../../../Context/User";
import { IconButton, Typography } from '@mui/material';
import { colors } from '../../../Utils/Constants';
import { useNavigate,useMatch } from 'react-router-dom';
const RightSide = ()=>{
    const {user,handleClickMenuList} = useUser();
    const navigate = useNavigate();
    const isLoginRoute = useMatch("/login");
    return(
        <div
            style={{
                display:"flex",
                justifyContent:"flex-start",
                alignItems:"center"
            }}
        >
            {
                    user ? <IconButton onClick={handleClickMenuList}>
                                <Avatar name={`${user.first_name} ${user.second_name}`} round size={30} />
                            </IconButton> :
                            <>
                                <Avatar style={{cursor:"no-drop"}} name={`I N V`} round size={30} />
                                {
                                    !isLoginRoute &&
                                    <Typography onClick={()=>navigate("/login")} style={{cursor:"pointer", marginLeft:10, color:colors.white}}>
                                        Iniciar Sesión
                                    </Typography>
                                }
                            </>
            }
        </div>    
    )
}

export default RightSide;