import { useNavigate } from 'react-router-dom';
const LeftSide = ()=>{
    const URLIMGLOGOWHITE = `${process.env.PUBLIC_URL}/img/john_milton_logo_white.png`;
    const navigate = useNavigate();
    return(
        <div 
            style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"flex-end"
            }}
        >
            <img src={URLIMGLOGOWHITE} alt="JM-LOGO" style={{width:90,marginRight:15,cursor:"pointer"}} onClick={()=>navigate("/")} />
        </div>
    )
}
export default LeftSide;