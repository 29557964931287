import LoginModal from "../../Components/Elements/LoginModal"
import Header from "../../Components/Elements/Header"
const LoginPage = ()=>{
    return(
        <div style={{width:"100%"}}>
            <Header />
            <LoginModal dontUseModal={true} redirect={true} />
            <div style={{height:150}}></div>
        </div>
    )
}
export default LoginPage;
