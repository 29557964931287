import React from "react";
import {colors,errorTagStyle} from "../../../Utils/Constants";
import {FaHandPointUp} from "react-icons/fa";
import LoginModal from "../LoginModal";
import { Fade } from "@mui/material";
const LoginComponent = (props)=>{
    const { rightPanel } = props;
    return(
        
        <Fade in={true} timeout={800}>
            <div>
                {
                    rightPanel ? 
                    <>
                        <div className="d-flex justify-content-end">
                            <span style={{color:colors.secondary,fontWeight:"bold",fontSize:10}}>
                                Iniciar Sesión
                            </span>  
                        </div>
                    </> : null
                }
                <LoginModal dontUseModal={true} redirect={false}/>
                {
                    rightPanel ? 
                    <>
                        <div style={errorTagStyle} className="d-flex justify-content-center">
                            <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                                <div style={{width:"30%"}} className="alineado">
                                    {
                                        <FaHandPointUp style={{fontSize:60,color:colors.secondary}} />
                                    }
                                </div>
                                <div style={{width:"70%",textAlign:"center"}}>
                                    <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                        Inicia sesión o registrate para continuar.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </> : null
                }
            </div>
        </Fade>
    )
}
export default LoginComponent;