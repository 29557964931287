import { Typography } from "@mui/material";
import {Container,Row,Col} from "react-bootstrap";
import Input from "../../Input";
import { useLoginContext } from "./store";
import { colors } from "../../../../Utils/Constants"; 
import IconButton from "../../IconButton";
const LoginForm = ()=>{
    const {
        checkPsw,
        email,setEmail,
        psw,setPsw,
        checkMail,
        load,
        checkFormErrors,
        func,
        loginApp
    } = useLoginContext();
    return(
        <Container>
            <Row>
                <Col xs="12">
                    <Input value={email} onChange={setEmail} errorMsg={checkMail()} placeholder="Correo electrónico" label="Email" />
                </Col>
                <Col xs="12">
                    <Input value={psw} onChange={setPsw} errorMsg={checkPsw()} placeholder="Contraseña" label="Contraseña" password />
                </Col>
                <Col xs="12" className="d-flex justify-content-center" style={{marginTop:15}}>
                    <IconButton icon="IoLogIn" type={checkFormErrors()?"danger":"info"} execute={loginApp} loader={load} title="Entrar" disabled={checkFormErrors()} style={{width:"80%"}} round />
                </Col>
                <Col xs="12">
                    <Typography onClick={func.showRegen} style={{color:colors.gray,textAlign:"center",fontSize:12,marginTop:15,cursor:"pointer"}}>
                        ¿Olvidaste tu contraseña?
                    </Typography>
                </Col>
                <Col xs="6" style={{marginTop:15}}>
                    <Typography
                        style={{
                            textAlign:"center",
                            color:colors.gray,
                            fontSize:12,
                            cursor:"no-drop"
                        }}
                    >
                        ¿No tienes una cuenta?
                    </Typography>
                </Col>
                <Col xs="6" style={{marginTop:15}}>
                    <Typography
                        onClick={func.showRegister}
                        style={{
                            textAlign:"center",
                            color:colors.secondary,
                            fontSize:12,
                            cursor:"pointer"
                        }}
                    >
                        Registrarme
                    </Typography>
                </Col>
            </Row>
        </Container>
    )
}
export default LoginForm;