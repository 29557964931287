const BACKEND = {
    LOCAL: "http://localhost:8082/",
    REMOTE: "https://duermase.net/",
    REMOTE_DEV: "https://develop.duermase.net/"
}
const DEV = process.env.REACT_APP_DEV ? process.env.REACT_APP_DEV.trim() === "true" : false;
const LOCAL = process.env.REACT_APP_LOCAL ? process.env.REACT_APP_LOCAL.trim() === "true" : false;
export const GetBackend = ()=>{
    if(DEV&&!LOCAL){
        console.log("DEV&&!LOCAL");
        return BACKEND.REMOTE_DEV;
    }else if(DEV&&LOCAL){
        console.log("DEV&&LOCAL");
        return BACKEND.LOCAL;
    }else{
        console.log("!DEV&&!LOCAL");
        return BACKEND.REMOTE;
    }
}
