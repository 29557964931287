import React from "react";
import {useUser} from "../../../Context/User";
import Modal from "../Modal";
import LoginContent from "./Content";

const LoginModal = ({dontUseModal,redirect})=>{
    const { 
        modalLogin,
        handleToogleModalLogin,
        user
    } = useUser();
    const [registerUser,setRegisterUser] = React.useState(null);
    const showRegister = ()=>setRegisterUser(true);
    const showRegen = ()=>setRegisterUser("regen");
    const showLogin = ()=>setRegisterUser(null);
    const getTitle = ()=> registerUser==="regen" ? "Regenerar Contraseña" : registerUser ? "Registro" : "Iniciar Sesión";
    if(!user||user==="Invitado"){
        if(dontUseModal){
            //console.log("RETORNANDO CONTENIDO SIN MODAL")
            return <LoginContent redirect={redirect} func={{showRegen,showLogin,showRegister}} registerUser={registerUser} modalLogin={modalLogin} cancel={()=>handleToogleModalLogin(false)} />
        }else{
            //console.log("RETORNANDO CONTENIDO CON MODAL")
            return(
                <Modal 
                    size={"md"}
                    title={getTitle()}
                    visible={modalLogin}
                    handleHide={()=>handleToogleModalLogin(false)}
                    Content={()=><LoginContent func={{showRegen,showLogin,showRegister}} registerUser={registerUser} modalLogin={modalLogin} cancel={()=>handleToogleModalLogin(false)} />}
                    url={null}
                    postData={{}}
                />
            )
        }
    }//<ContentModalLogin registerUser={registerUser} showRegen={showRegen} showLogin={showLogin} showRegister={showRegister} cancel={()=>handleToogleModalLogin(false)} />
    return null;
}
export default LoginModal;